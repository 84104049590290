import { useTranslation } from 'next-i18next';
import RenderLinks from './RenderLinks';
import { LOGIN_LINKS } from '../../constant';

const ToSignup = () => {
  const { t } = useTranslation();
  return (
    <div className="menu-section">
      <div className="menu-title">{t('profile')}</div>
      <ul>
        <RenderLinks links={LOGIN_LINKS} />
      </ul>
      <div className="menu-line" />
    </div>
  );
};

export default ToSignup;
