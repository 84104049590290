import { useTranslation } from 'next-i18next';
import React, { forwardRef } from 'react';
import { useRouter } from 'next/router';
import { useContextDispatch, useContextState } from '../context';
import RenderLinks from './RenderLinks';
import { wrap } from '../../helper';
import { logOutUser } from '../Login/service';

const LINKS = [
  { href: '/terminal/settings', name: 'setting', internal: true },
  { href: '/terminal/personal-licenses', name: 'link_personalLicenses', internal: true },
  { href: '/terminal/order-history', name: 'order_history_title', internal: true },
  { href: '/terminal/personal-password', name: 'password', internal: true },
];

const SignedUp = (props, ref) => {
  const { t } = useTranslation();
  const { push } = useRouter();

  const {
    user: { name },
  } = useContextState();

  const { authorizedAction } = useContextDispatch();

  return (
    <div className="menu-section menu-profile" ref={ref}>
      <div className="menu-profile-name">{name}</div>
      <div className="menu-title">{t('profile')}</div>
      <ul className="top">
        <RenderLinks links={LINKS} />
      </ul>
      <ul className="bottom">
        <li>
          <button type="button" className="btn" onClick={wrap(logOutUser, authorizedAction, push)}>
            {t('signOut')}
          </button>
        </li>
      </ul>
      <div className="menu-line" />
    </div>
  );
};

export default forwardRef(SignedUp);
