import { createAuthorizationHeader, instanceFetch } from '../../fetch';
import { LocalStorageService } from '../../helper';

export const loginUser = (
  body,
  notificationShowAction,
  authorizedAction,
  hasExpiredLicenseAction,
  to,
  notifler
) => {
  return instanceFetch
    .post('/users/login', body)
    .then(({ accessToken, refreshToken }) => {
      LocalStorageService.set('accessToken', accessToken);
      LocalStorageService.set('refreshToken', refreshToken);
      authorizedAction(!!accessToken);
      to();
    })
    .catch(() => {
      notificationShowAction({
        text: notifler,
        color: 'red',
      });
    });
};

export const logOutUser = (authorizedAction, push) => {
  const headers = createAuthorizationHeader();
  authorizedAction(false);
  LocalStorageService.remove('refreshToken');
  localStorage.removeItem('refreshToken');
  return instanceFetch
    .post('/users/logout', {}, headers)
    .then(() => {
      LocalStorageService.remove('accessToken');
      localStorage.removeItem('accessToken');
      LocalStorageService.remove('refreshToken');
      localStorage.removeItem('refreshToken');
      push('/terminal');
    })
    .catch();
};
