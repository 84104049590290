import Link from 'next/link';
import React, { memo } from 'react';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

const RenderLinks = ({ links }) => {
  const { t } = useTranslation();
  const { asPath } = useRouter();

  return links.map(
    ({
      name,
      href,
      icon,
      cb,
      internal,
      className = '',
      activeClassName = 'active',
      componentTop = null,
      componentBottom = null,
      target = '',
      extraChild,
    }) => {
      if (cb) {
        return (
          <li onClick={cb} key={name} className={className}>
            <span>{t(name)}</span>
            {icon && icon}
            {extraChild && extraChild}
          </li>
        );
      }

      const activeNow =
        asPath.indexOf('/how-to-setup/') !== 0
          ? asPath === href
          : asPath.indexOf('/how-to-setup/') === href.indexOf('/how-to-setup/');
      if (internal) {
        return (
          <li key={name} className={`${className} ${activeNow ? activeClassName : ''}`}>
            <Link href={href}>
              <a target={target}>
                {componentTop}
                {t(name)}
                {componentBottom}
              </a>
            </Link>
            {icon && icon}
          </li>
        );
      }
      return (
        <li key={name} className={className}>
          <a href={href} target={target}>
            {t(name)}
          </a>
          {icon && icon}
        </li>
      );
    }
  );
};

export default memo(RenderLinks);
