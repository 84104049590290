import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { forwardRef } from 'react';
import { LANGUAGES } from '../../constant';
import { LocalStorageService, wrapEvent } from '../../helper';
import Icon from './Icon';

const Language = ({ setOpenLangMenu }, ref) => {
  const { locale, asPath } = useRouter();
  const { t } = useTranslation();

  return (
    <div ref={ref} className="menu-lang-sub">
      <div className="back head-lang" onClick={wrapEvent(setOpenLangMenu, false)}>
        <Icon name="20-darr" file="sprite" width="20" height="20" />
        {t('back')}
      </div>
      <div className="wrap-scroll">
        <div className="scroll">
          <ul>
            {LANGUAGES.map(({ name, value }) => (
              <li key={name} className={locale === value ? 'active' : ''}>
                <Link href={asPath} locale={value} passHref>
                  <span
                    onClick={() => {
                      LocalStorageService.set('language', value);
                    }}
                  >
                    {t(name)}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Language);
