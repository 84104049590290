import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Icon from './Icon';

const SOC_IMG = [
  // { name: '50-facebook', link: 'social-href_facebook' },
  { name: '50-twitter', link: 'social-href_twitter' },
  { name: '50-telegram', link: 'social-href_telegram' },
  { name: '50-youtube', link: 'social-href_youtube' },
  { name: '50-discord', link: 'social-href_discord' },
];

const SocialLinks = (props) => {
  const { className } = props;
  const { t } = useTranslation();
  const { locale } = useRouter();

  return (
    <div className={className + ' soc-container'}>
      <p className="soc-title">{t('footer_section3')}</p>
      <div className="soc">
        {SOC_IMG.map(({ name, link }) => {
          if (locale === 'ru' && name === '50-facebook') return null;
          return (
            <a href={t(link)} key={name} target="_blank" rel="nofollow noreferrer">
              <Icon name={name} file="sprite" width="50" height="50" />
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SocialLinks;
