import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { LANGUAGES } from '../../constant';
import { wrapEvent } from '../../helper';
import CopytradingIcon from '../../public/images/menu/copytrading.svg';
import MacOSIcon from '../../public/images/menu/macos.svg';
import BrokerIcon from '../../public/images/menu/broker.svg';
import MainIcon from '../../public/images/menu/main.svg';
import WindowsIcon from '../../public/images/menu/windows.svg';
import ArrowIcon from '../../public/img/icons/icon_arrow.svg';
import WindowsDownloadIcon from '../../public/img/icons/icon_windows.svg';
import Logo from '../../public/img/logo-new.svg';
import { useOutsideAlerter } from '../../src/hooks/useOutsideClick';
import Icon from '../common/Icon';
import Language from '../common/Language';
import RenderLinks from '../common/RenderLinks';
import SignedUp from '../common/SignedUp';
import SocialLinks from '../common/SocialLinks';
import ToSignup from '../common/ToSignup';
import { useContextState } from '../context';
import style from './style.module.scss';
import { TERMINAL_MAC_OS_LINKS } from '@/core/constants';

const LeftIcon = (
  <span className="pic pic-left">
    <Icon name="24-windows" file="sprite" width="24" height="24" />
  </span>
);

const RightIcon = (
  <span className="pic">
    <Icon name="24-download" file="sprite" width="24" height="24" />
  </span>
);

const USEFUL_LINKS = [
  /*   { href: 'google.com', name: 'footer_pages_purchase', internal: false },
    { href: 'google.com', name: 'footer_pages_documentation', internal: false },
    { href: '/overview', name: 'link_overview', internal: true }, */
  {
    href: '/terminal/privacy-policy',
    name: 'link_privacyPolicy',
    internal: true,
    className: style.link,
    activeClassName: style.linkActive,
  },
  {
    href: '/terminal/terms-of-service',
    name: 'link_termsAndRules',
    internal: true,
    className: style.link,
    activeClassName: style.linkActive,
  },
  {
    href: '/terminal/end-user-license-agreement',
    name: 'link_endUserLicenseAgreement',
    internal: true,
    className: style.link,
    activeClassName: style.linkActive,
  },
];

const LOGIN_LINKS = [
  { href: '/terminal/login', name: 'login', internal: true, className: style.logInLink },
];

const activeLanguage = (locale) => {
  const lng = LANGUAGES.find((i) => i.value === locale);
  if (lng) {
    return lng.name;
  }
  return 'language';
};

const handleClickOutside = (node, node2, fn) => (e) => {
  if (node && !node.current.contains(e.target) && node2 && !node2.current.contains(e.target)) {
    fn(false);
  }
};

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [openLangMenu, setOpenLangMenu] = useState(false);
  const [isOpenProducts, setIsOpenProducts] = useState(false);
  const [isOpenProductsMobile, setIsOpenProductsMobile] = useState(false);
  const {
    authorized,
    user: { email },
  } = useContextState();

  const menuRef = useRef(null);
  const selectedLanguageRef = useRef(null);
  const userMenuRef = useRef(null);
  const userRef = useRef(null);
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!hovered);
  const productsMenuRef = useRef();

  const productsLinks = [
    {
      id: 0,
      href: `/${locale}`,
      name: 'header_in_products_main_redirect_button',
      subName: 'header_in_products_main_subname_redirect_button',
      menuIcon: <MainIcon className={style.fallbackMain} />,
    },
    {
      id: 1,
      href: '/terminal',
      name: 'header_in_products_windows_terminal_redirect_button',
      subName: 'header_in_products_windows_subname_redirect_button',
      menuIcon: <WindowsIcon />,
      active: true,
    },
    {
      id: 2,
      href: TERMINAL_MAC_OS_LINKS[locale],
      name: 'header_products_mac_terminal_redirect_button',
      subName: 'header_in_products_macos_subname_redirect_button',
      menuIcon: <MacOSIcon />,
    },
    {
      id: 3,
      href: locale !== 'ru' ? 'https://broker.tiger.trade/' : 'https://broker.tiger.trade/ru/',
      name: 'header_products_broker_redirect_button',
      subName: 'header_in_products_broker_subname_redirect_button',
      menuIcon: <BrokerIcon />,
    },
    /*
    {
      id: 4,
      href:
        locale !== 'ru'
          ? 'https://copy.tiger.trade/index-en.html'
          : 'https://copy.tiger.trade/index-ru.html',
      name: 'header_in_products_copytrading_redirect_button',
      subName: 'header_in_products_copy_subname_redirect_button',
      menuIcon: <CopytradingIcon />,
    },
    */
  ];

  const PAGES = [
    {
      href: '/terminal',
      name: 'link_home',
      internal: true,
      className: style.link,
      activeClassName: style.linkActive,
    },
    {
      cb: () => setIsOpenProductsMobile(!isOpenProductsMobile),
      name: 'header_products_dropdown_button',
      className: `${style.link} ${clsx(
        style.mobileProductLink,
        isOpenProductsMobile ? style.mobileProductLinkActive : ''
      )}`,
      activeClassName: style.linkActive,
      extraChild: isOpenProductsMobile ? (
        <div className={style.mobileProducts}>
          {productsLinks.map((link) => (
            <a href={link.href} key={link.id} className={style.mobileProductsItem}>
              {t(link.name)}
            </a>
          ))}
        </div>
      ) : null,
    },
    {
      href: '/terminal/overview',
      name: 'link_overview',
      internal: true,
      className: style.link,
      activeClassName: style.linkActive,
    },
    {
      href: '/terminal/how-to-setup',
      name: 'link_howToSetup',
      internal: true,
      className: style.link,
      activeClassName: style.linkActive,
    },
    {
      href: '/terminal/prices',
      name: 'link_prices',
      internal: true,
      className: style.link,
      activeClassName: style.linkActive,
    },
    {
      href: `${t('support_url')}`,
      name: 'link_support',
      internal: true,
      target: '_blank',
      className: style.link,
      activeClassName: style.linkActive,
    },
    {
      href: `${t('affilates_url')}`,
      name: 'link_affilates',
      internal: true,
      target: '_blank',
      className: style.link,
      activeClassName: style.linkActive,
    },
    // {
    //   href: '/terminal/download',
    //   name: 'link_download',
    //   internal: true,
    //   className: 'link-download',
    //   componentTop: LeftIcon,
    //   componentBottom: RightIcon,
    // },
  ];

  useEffect(() => {
    const el = document.getElementById('__next');
    const click = handleClickOutside(menuRef, selectedLanguageRef, setOpenLangMenu);

    if (openLangMenu && el) {
      el.classList.add('open-lang-menu');
      document.addEventListener('click', click, true);
    }

    return () => {
      if (openLangMenu && el) {
        el.classList.remove('open-lang-menu');
        document.removeEventListener('click', click, true);
      }
    };
  }, [openLangMenu]);

  useEffect(() => {
    const el = document.getElementById('__next');
    if (openMenu && el) {
      el.classList.add('open-menu');
    }

    return () => {
      if (openMenu && el) {
        el.classList.remove('open-menu');
      }
    };
  }, [openMenu]);

  useEffect(() => {
    const el = document.getElementById('__next');
    const click = handleClickOutside(userMenuRef, userRef, setOpenUserMenu);

    if (openUserMenu && el) {
      el.classList.add('open-user-menu');
      document.addEventListener('click', click, true);
    }

    return () => {
      if (openUserMenu && el) {
        el.classList.remove('open-user-menu');
        document.removeEventListener('click', click, true);
      }
    };
  }, [openUserMenu]);

  useEffect(() => {
    return () => {
      setOpenUserMenu(false);
    };
  }, [authorized]);

  useOutsideAlerter(productsMenuRef, () => setIsOpenProducts(false), 200);

  return (
    <header className="header">
      <div className="grid">
        <div className="headline">
          <div className={style.logo + ' logo'}>
            <Link href="/terminal" passHref>
              <span>
                <Logo />
              </span>
            </Link>
            <div
              className={clsx(
                style.productChanger,
                isOpenProducts ? style.productChangerActive : ''
              )}
              onClick={() => setIsOpenProducts(!isOpenProducts)}
            >
              <span className={style.productChangerTitle}>Tiger.Trade Windows</span>
              <ArrowIcon />
              {isOpenProducts && (
                <div ref={productsMenuRef} className={style.productList}>
                  {productsLinks.map((link) => (
                    <Link key={link.id} href={link.href}>
                      <span className={style.listItem}>
                        {link.menuIcon && link.menuIcon}
                        <div
                          className={clsx(
                            style.textWrapper,
                            link.active ? style.textWrapperActive : ''
                          )}
                        >
                          <div className={style.itemTitle}>{t(link.name)}</div>
                          {link.subName && (
                            <div className={style.itemSubTitle}>{t(link.subName)}</div>
                          )}
                        </div>
                      </span>
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className="toggle-menu" onClick={wrapEvent(setOpenMenu, !openMenu)}>
            <div className="pos1" />
            <div className="pos2" />
          </div>
        </div>
        <div className="wrap-menu">
          <div className="menu-padding">
            {authorized ? <SignedUp ref={userMenuRef} /> : <ToSignup />}
            <div
              className={hovered ? 'menu-section menu-main hover' : 'menu-section menu-main'}
              onMouseOver={toggleHover}
              onMouseOut={toggleHover}
            >
              <div className="menu-title">{t('footer_pages')}</div>
              <ul>
                <RenderLinks links={PAGES} />
              </ul>
            </div>
            <div className="menu-section">
              <div className="menu-title">{t('footer_pages_usefulLinks')}</div>
              <ul>
                <RenderLinks links={USEFUL_LINKS} />
              </ul>
            </div>
            <div className="menu-section menu-lang">
              <div className="menu-title">{t('language')}</div>
              <ul>
                <li>
                  <div
                    ref={selectedLanguageRef}
                    className={style.langChanger + ' toggle head-lang'}
                    onClick={wrapEvent(setOpenLangMenu, !openLangMenu)}
                  >
                    <span>{locale}</span>
                  </div>
                </li>
              </ul>
            </div>
            <Link href={'/terminal/download'}>
              <div className={style.downloadLink}>
                <WindowsDownloadIcon />
                <span>{t('link_download')}</span>
              </div>
            </Link>
            <div className="menu-section menu-contact">
              <div className="text">
                <div className="menu-title">{t('footer_email')}</div>
                <a href={`mailto:${t('footer_email_text')}`}>{t('footer_email_text')}</a>
              </div>
              <SocialLinks />
            </div>
          </div>
        </div>
        {authorized && email ? (
          <div
            className="head-user"
            onClick={wrapEvent(setOpenUserMenu, !openUserMenu)}
            ref={userRef}
          >
            <div className="email">{email}</div>
            <span className="arr">
              <Icon name="20-darr" file="sprite" width="20" height="20" />
            </span>
          </div>
        ) : (
          <RenderLinks links={LOGIN_LINKS} />
        )}
        <Language ref={menuRef} setOpenLangMenu={setOpenLangMenu} />
      </div>
    </header>
  );
};

export default Header;
